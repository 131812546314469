<template lang="">
  <div class="van-collapse-item">
    <CollapseItem title="客户信息" name="1">
      <div class="item">
        <span class="label">客户姓名：</span>
        <span class="value">{{ orderInfo.customerName | dash }}</span>
      </div>
      <div class="item">
        <span class="label">联系电话：</span>
        <span class="value">{{ orderInfo.mobile | encryptPhone }}</span>
      </div>
      <div class="item">
        <span class="label">客户地区：</span>
        <span class="value">{{
          orderInfo.provinceName
            ? orderInfo.provinceName + "-" + orderInfo.cityName
            : "-"
        }}</span>
      </div>
      <div class="item">
        <span class="label">商户信息：</span>
        <span class="value">{{ orderInfo.merchantName | dash }}</span>
      </div>
    </CollapseItem>
    <CollapseItem title="车辆信息" name="2">
      <div class="item">
        <span class="label">车牌号：</span>
        <span class="value">{{ orderInfo.vehiclePlateNo | dash }}</span>
      </div>
      <div class="item">
        <span class="label">VIN码：</span>
        <span class="value">{{ orderInfo.vehicleVin | dash }}</span>
      </div>
      <div class="item">
        <span class="label">车辆信息：</span>
        <span class="value">{{ orderInfo.vehicleModelFullName | dash }}</span>
      </div>
      <div class="item">
        <span class="label">押品状态：</span>
        <span class="value">{{ orderInfo.leaseStatusStr | dash }}</span>
      </div>
      <div class="item">
        <span class="label">收车：</span>
        <span class="value">{{ orderInfo.recoveryDate | dash }}</span>
      </div>
      <div class="item">
        <span class="label">入库：</span>
        <span class="value">{{ orderInfo.inDate | dash }}</span>
      </div>
    </CollapseItem>
    <CollapseItem
      :title="projectCode"
      name="3"
      class="label-top"
      :value="orderInfo.productName | dash"
      disabled
      :is-link="false"
    >
      <div class="item">
        <span class="label">融资金额：</span>
        <span class="value">{{ orderInfo.amount | amount | dash }}</span>
      </div>
      <div class="item">
        <span class="label">应收余额：</span>
        <span class="value">{{
          findByBizInfo.currentTotalBalance | amount | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label">代偿拖欠金额：</span>
        <span class="value">{{
          findByBizInfo.platformTransferTotalRemain | amount | dash
        }}</span>
      </div>
      <div class="item">
        <span class="label">逾期拖欠金额：</span>
        <span class="value">{{ findByBizInfo.currentOverdueAmount | amount | dash }}</span>
      </div>
      <div class="item">
        <span class="label">最近还款日：</span>
        <span class="value">{{ orderInfo.lastRepaymentDate | dash }}</span>
      </div>
      <!-- 资方债权-回购标识 -->
      <div class="badges" v-if="isshowYP">
        <ProcessBadge
          class="badge-item"
          :text="`${transfereeLabel}-${
            findByBizInfo.debtSettleFlag === 'Y' ? '债权结清' : '债权受让'
          }`"
          v-if="findByBizInfo.debtTansferee"
          :color="'#1cade0'"
          :rotate="15"
          :title="`${
            findByBizInfo.debtSettleFlag === 'Y' ? '债权结清' : '债权受让'
          }`"
        />
        <ProcessBadge
          class="badge-item"
          :text="`${findByBizInfo.secondTransferTypeStr}-${findByBizInfo.secondOwnerNameText}`"
          v-if="
            findByBizInfo.secondOwnerName && findByBizInfo.secondTransferTypeStr
          "
          :color="
            findByBizInfo.secondTransferType === 'USUFRUCT_ZR'
              ? '#ff458e'
              : '#5be080'
          "
          :rotate="15"
        />
        <template v-else>
          <!-- 资方债权-第二次买债人姓名 -->
          <ProcessBadge
            class="badge-item"
            :text="`受益人-${findByBizInfo.secondOwnerNameText}`"
            v-if="findByBizInfo.secondOwnerName"
            :color="'#27e0dd'"
            :rotate="15"
            title="受益人"
          />
        </template>
        <!-- 受益人 -->
        <ProcessBadge
          class="badge-item"
          :text="`受益人-${haveBeneficiaryName}`"
          v-if="haveBeneficiaryName"
          :color="'#d5ae7a'"
          :rotate="15"
          title="受益人"
        />
        <ProcessBadge
          v-if="findByBizInfo"
          class="badge-item"
          :text="
            findByBizInfo.iouStatus === 'OVERDUE'
              ? `逾期 ${findByBizInfo.currentOverdueDay} 天`
              : findByBizInfo.iouStatusStr
          "
          :color="findByBizInfo.iouStatus === 'OVERDUE' ? '#ff8e8e' : '#9edfe0'"
          :textSize="findByBizInfo.iouStatus === 'OVERDUE' ? 100 : 120"
          :rotate="15"
          title="借据状态"
        />
        <ProcessBadge
          class="badge-item"
          :title="'黑名单'"
          style="cursor: pointer"
          :text="'黑名单'"
          :color="'#f00'"
          :textSize="100"
          :rotate="15"
          v-if="orderInfo.forbiddenFlag == '1'"
        />
      </div>
    </CollapseItem>
  </div>
</template>
<script>
import Api from "@/api/index.js";
import { CollapseItem } from "vant";
import ProcessBadge from "@/components/ProcessBadge.vue";

export default {
  props: {
    projectCode: {
      type: String,
      default: "",
    },
    isshowYP: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderInfo: {},
      findByBizInfo: {},
      outsourceCase: {},
      haveBeneficiaryName: "",
    };
  },
  components: { ProcessBadge, CollapseItem },
  created() {
    this.init();
  },
  computed: {
    recoveryDate() {
      let ht = "";
      let { inDate, recoveryDate } = this.orderInfo;
      if (inDate) ht += `入库-${inDate}`;
      if (recoveryDate) ht += `收车-${recoveryDate}`;
      return ht;
    },
    transfereeLabel() {
      if (this.findByBizInfo.debtTansferee.indexOf("勤德") !== -1) {
        return this.findByBizInfo.debtTansferee.indexOf("陕西勤德") !== -1
          ? "陕西勤德"
          : "重庆勤德";
      } else if (this.findByBizInfo.debtTansferee.indexOf("债易宝") !== -1) {
        return "债易宝";
      } else if (this.findByBizInfo.debtTansferee.indexOf("颂车") !== -1) {
        return "颂车";
      } else {
        return this.findByBizInfo.debtTansferee;
      }
    },
  },
  methods: {
    init() {
      Api.getHaveBeneficiaryName(this.projectCode).then((res) => {
        let data = res.data;
        this.haveBeneficiaryName = data ? data?.slice(0, 3) : "";
      });
      Api.getCompleteInfo(this.projectCode).then((res) => {
        this.orderInfo = res.data || {};
      });
      Api.findByBizNo(this.projectCode).then((res) => {
        this.findByBizInfo = res.data || {};
        let secondOwnerNameText = `${
          this.findByBizInfo.secondOwnerName &&
          this.findByBizInfo.secondOwnerName.slice(0, 4)
        }*`;
        this.findByBizInfo.secondOwnerNameText = secondOwnerNameText;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  &:last-child {
    margin-bottom: 0;
  }
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
  .dispatch {
    background-color: #f5f7f9;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      color: #333333;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        button {
          padding: 0 12px;
        }
      }
    }
    .item {
      align-items: center;
    }
  }
  &.label-top {
    /deep/ .van-collapse-item__content {
      display: flex;
      flex-wrap: wrap;
      .badges{
        width:100%;
      }
      .item {
        width: 50%;
        flex-direction: column;
        .label {
          flex: 1;
        }
        .value {
          font-weight: bold;
        }
      }
    }
  }
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
      &.large {
        flex: 0 0 100px;
      }
    }
    .value {
      flex: 1;
      color: #333;
    }
  }
}
</style>
