<template>
  <div class="custom">
    <div class="sticky">
      <NavBar title="案件分派" left-arrow @click-left="onClickLeft" />
    </div>
    <div class="content">
      <Collapse v-model="basicActiveNames">
        <BasicInfo :projectCode="projectCode"></BasicInfo>
        <CollapseItem
          title="案件分派"
          name="3"
          icon="/images/icon-case-dispatch.png"
          :is-link="false"
          disabled
        >
          <Form ref="form" validate-first>
            <Field
              readonly
              clickable
              required
              name="mode"
              :value="form.modeStr"
              clearable
              label="模式选择"
              placeholder="请选择"
              :rules="[{ required: true }]"
              @click="showModePicker = true"
              input-align="right"
            ></Field>
            <Field
              readonly
              clickable
              required
              name="way"
              :value="form.wayStr"
              clearable
              label="清收方式"
              placeholder="请选择"
              :rules="[{ required: true }]"
              @click="modeSelect"
              input-align="right"
            ></Field>
            <Field
              v-if="form.mode == 'SPECIFY'"
              readonly
              clickable
              required
              :value="form.orgName"
              clearable
              name="orgNo"
              label="清收机构"
              placeholder="请选择"
              :rules="[{ required: true }]"
              @click="showOrgNamePicker = true"
              input-align="right"
            ></Field>
            <Field
              v-if="
                ['CAR', 'MULTI_WAY'].includes(form.way) &&
                form.mode == 'SPECIFY'
              "
              class="wth"
              required
              label="生成委托函"
              v-model="form.pdf"
              :rules="[{ required: true, message: '请生成委托函' }]"
              input-align="right"
            >
              <template #input>
                <Button
                  size="small"
                  @click="buildHorization"
                  type="primary"
                  plain
                  >生成委托函</Button
                >
                <Button
                  v-if="['CAR', 'MULTI_WAY'].includes(form.way) && form.pdf"
                  size="small"
                  @click="check"
                  type="primary"
                  plain
                >
                  <Icon name="browsing-history-o" />
                  查看pdf
                </Button>
              </template>
            </Field>
            <Field
              v-if="
                ['CAR', 'MULTI_WAY'].includes(form.way) &&
                form.mode == 'SPECIFY'
              "
              class="wth"
              label="上传委托函"
              input-align="right"
            >
              <template #input>
                <Uploader
                  v-model="form.files"
                  :before-read="upload"
                  :before-delete="deleteFile"
                />
              </template>
            </Field>
            <Field
              required
              v-model="form.outsourceReason"
              name="outsourceReason"
              rows="2"
              type="textarea"
              :maxLength="200"
              show-word-limit
              label="清收原因"
              placeholder="请输入"
              :rules="[{ required: true }]"
              input-align="right"
            ></Field>
          </Form>
        </CollapseItem>
      </Collapse>
    </div>
    <div class="bottom-btn">
      <div class="btns">
        <Button type="primary" hairline @click="onSubmit">确认</Button>
      </div>
    </div>
    <Popup v-model="showModePicker" position="bottom">
      <Picker
        title="模式选择"
        show-toolbar
        value-key="displayName"
        :columns="recoverModeEnum"
        @confirm="modeConfirm"
        @cancel="showModePicker = false"
      ></Picker>
    </Popup>
    <Popup v-model="showWayPicker" position="bottom">
      <Picker
        title="清收方式"
        show-toolbar
        value-key="displayName"
        :columns="currentRecoverWayEnum"
        @confirm="wayConfirm"
        @cancel="showWayPicker = false"
      ></Picker>
    </Popup>
    <Popup v-model="showOrgNamePicker" position="bottom" class="search-picker">
      <Search v-model="keyLike" placeholder="请输入清收机构" @search="search" />
      <Picker
        title="清收机构"
        show-toolbar
        value-key="orgName"
        :columns="organList"
        @confirm="orgNameConfirm"
        @cancel="showOrgNamePicker = false"
      ></Picker>
    </Popup>
    <PdfViewer ref="PdfViewer" :pdfUrl="this.form.pdf"></PdfViewer>
  </div>
</template>

<script>
import {
  NavBar,
  Collapse,
  CollapseItem,
  Button,
  Form,
  Field,
  Picker,
  Popup,
  Uploader,
  Toast,
  Search,
  Icon,
} from "vant";
import BasicInfo from "../custom/components/BasicInfo";
import PdfViewer from "@/components/PdfViewer.vue";
import { deepCopy } from "@/common/utils";
import Api from "@/api/index.js";
import { mapState } from "vuex";
export default {
  name: "Trial",
  components: {
    NavBar,
    Collapse,
    CollapseItem,
    Button,
    Form,
    Field,
    Picker,
    Popup,
    BasicInfo,
    Uploader,
    PdfViewer,
    Search,
    Icon,
  },
  data() {
    return {
      activeName: "basic",
      basicActiveNames: ["1", "2", "3"],
      pattern: /^\s*$/,
      showModePicker: false,
      showWayPicker: false,
      showOrgNamePicker: false,
      firstname: [],
      recoverWayEnum: [],
      recoverModeEnum: [],
      organList: [],
      allOrganList: [],
      outsourceCase: {},
      keyLike: "",
      form: {
        outsourceReason: "",
        way: "",
        mode: "",
        pdf: "",
        files: [
          // {
          //   url: "https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20240126/ISSPGI8Kgm7SF6BMtp6GpBeSwDe7nyfW.pdf",
          // },
        ],
      },
    };
  },
  created() {
    this.projectCode = this.$route.query.projectCode;
    this.firstname = [0];
    this.init();
  },
  computed: {
    ...mapState(["info"]),
    orderActiveNames: {
      get() {
        return this.firstname;
      },
      set(v) {
        this.firstname = v;
      },
    },
    currentRecoverWayEnum() {
      if (this.form.mode === "SHARED") {
        return this.recoverWayEnum.slice(1);
      }
      return this.recoverWayEnum;
    },
  },
  methods: {
    init() {
      Api.getRecoverWayEnum().then(({ data }) => {
        this.recoverWayEnum = data || [];
      });
      Api.getRecoverModeEnum().then(({ data }) => {
        this.recoverModeEnum = data || [];
      });
      Api.getOutsourceCase(this.projectCode).then(({ data }) => {
        this.outsourceCase = data || {};
      });
    },
    async upload(file) {
      const params = new FormData();
      params.append("file", file);
      Toast.loading({
        message: "上传中..",
        forbidClick: true,
        duration: 0,
      });
      const res = await Api.uploadFile(params);
      Toast.clear();
      if (res.success) {
        if (!this.form.files) {
          this.form.files = [];
        }
        this.form.files.push({ url: res.data.ossAccessUrl });
        return Promise.reject();
      }
    },
    // 删除
    deleteFile(file, o) {
      this.form.files.splice(o.index, 1);
      return file;
    },

    //生成委托函
    buildHorization() {
      if (!this.form.orgNo) {
        Toast("请先选择指派机构");
        return;
      }
      let paramsList = [];
      paramsList.push({
        ...this.outsourceCase,
        orgNo: this.form.orgNo,
        mode: this.form.mode,
        way: this.form.way,
      });
      const toast = Toast.loading({
        message: "委托函生成中...",
        duration: 0,
        forbidClick: true,
      });
      this.horizationLoading = true;
      Api.generateAuthorization(paramsList)
        .then(({ data, success, message }) => {
          this.horizationLoading = false;
          toast.clear();
          if (!success) {
            Toast(message || "出错了");
            return;
          }
          if (Array.isArray(data) && data.length > 0) {
            if (data[0].isAuth == "NO" || data[0].result == "FAIL") {
              Toast(data[0].failReason || "出错了");
              return;
            }
            this.isBuildHorization = true;
            this.$set(this.form, "pdf", data[0].url);
          }
        })
        .catch(() => {
          toast.clear();
          this.horizationLoading = false;
        });
    },
    getOrganList() {
      Api.getOrganList({
        modeEq: this.form.mode,
        type: "OUTSOURCE",
        wayEq: this.form.way,
      }).then(({ data }) => {
        this.organList = data || [];
        this.allOrganList = data || [];
      });
    },
    async onSubmit() {
      console.log("this.form :>> ", this.form);
      await this.$refs.form.validate();
      if (this.form.mode == "SPECIFY" && !this.form.orgNo) {
        Toast("清收机构必选");
        return;
      }
      let params = deepCopy([{ ...this.outsourceCase }]) || [];
      params.forEach((item) => {
        item.pdf = this.form.pdf;
        item.mode = this.form.mode;
        item.way = this.form.way;
        item.orgNo = this.form.orgNo;
        item.orgName = this.form.orgName;
        item.outsourceReason = this.form.outsourceReason;
      });
      params[0].files =
        this.form.files.map((it) => {
          return it.url;
        }) || [];
      this.loading = true;
      const toast = Toast.loading({
        message: "请求中...",
        duration: 0,
        forbidClick: true,
      });
      Api.assignDispatch({
        assignVOList: params,
        operatorld: this.info.userId,
        operatorName: this.info.username,
      })
        .then(({ success, message }) => {
          this.loading = false;
          toast.clear();
          if (!success) {
            Toast(message || "出错了");
            return;
          }
          Toast("分派成功");
          setTimeout(() => {
            this.onClickLeft();
          }, 1000);
        })
        .catch(() => {
          toast.clear();
          this.loading = false;
        });
    },
    modeConfirm({ value, displayName }) {
      this.form.modeStr = displayName;
      this.$set(this.form, "mode", value);
      this.showModePicker = false;
      if (this.form.mode == "SHARED") {
        if (this.form.way == "PHONE") {
          this.form.wayStr = "";
          this.$set(this.form, "way", "");
        }
        if (this.form.orgNo) {
          this.form.orgNo = "";
          this.form.orgName = "";
        }
        if (this.form.files.length) {
          this.form.files = [];
        }
      }
    },
    wayConfirm({ value, displayName }) {
      this.form.wayStr = displayName;
      this.$set(this.form, "way", value);
      this.showWayPicker = false;
      this.form.orgName = "";
      this.$set(this.form, "orgNo", "");
      this.getOrganList();
    },
    orgNameConfirm({ orgNo, orgName }) {
      this.form.orgName = orgName;
      this.$set(this.form, "orgNo", orgNo);
      this.showOrgNamePicker = false;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    clear() {
      this.time = "";
    },
    save() {
      console.log("this.form :>> ", this.form);
    },

    modeSelect() {
      if (!this.form.mode) return Toast("请先选择模式");
      this.showWayPicker = true;
    },

    check() {
      this.$refs.PdfViewer.show();
    },

    search() {
      this.organList = this.allOrganList.filter((item) =>
        item.orgName.includes(this.keyLike)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.search-picker {
  .van-search {
    position: absolute;
    top: 40px;
    z-index: 111;
    width: 100%;
  }
}
/deep/ .van-nav-bar__content {
  .van-icon,
  .van-nav-bar__title {
    color: #fff;
  }
}
.sticky {
  padding-bottom: 50px;
  background: linear-gradient(
    180deg,
    #59c47e 0%,
    rgba(89, 196, 126, 0.6) 44%,
    rgba(245, 247, 249, 0.75) 100%
  );
}
.content {
  padding: 12px;
  font-size: 14px;
  margin-top: -50px;
  .wth {
    /deep/.van-field__label {
      width: 90px;
    }
    /deep/.van-field__body {
      .van-field__control {
        flex-direction: column;
        align-items: flex-end;
        .van-button {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.van-nav-bar {
  background: none;
}
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
  &.label-top {
    /deep/ .van-collapse-item__content {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        flex-direction: column;
        .label {
          flex: 1;
        }
        .value {
          font-weight: bold;
        }
      }
    }
  }
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
      &.large {
        flex: 0 0 100px;
      }
    }
    .value {
      flex: 1;
      color: #333;
    }
  }
}
</style>
